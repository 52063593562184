<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->

                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="
                      font-weight-bolder
                      text-dark
                      font-size-h6 font-size-h3-lg
                    "
                  >
                    Detail Pendaftaran Karakteristik SEM
                  </h5>
                  <table class="table mt-8">
                    <tr>
                      <th class="pr-1">
                        <span>Mahasiswa</span>
                      </th>
                      <th>
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.student_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>NIM</span>
                      </th>
                      <th>
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.registration_number }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Semester ke-</th>
                      <th><span>:</span></th>
                      <td>{{ detail.registration_number }}</td>
                    </tr>
                    <tr>
                      <th>Tahun ke-</th>
                      <th><span>:</span></th>
                      <td>{{ detail.year_id }}</td>
                    </tr>
                    <tr>
                      <th>Program Studi</th>
                      <th><span>:</span></th>
                      <td>{{ detail.study_program_name }}</td>
                    </tr>
                    <tr>
                      <th>Fakultas</th>
                      <th><span>:</span></th>
                      <td>{{ detail.faculty_name }}</td>
                    </tr>
                    <!--  -->
                    <tr>
                      <th>Kepeminatan</th>
                      <th><span>:</span></th>
                      <td>{{ detail.interest }}</td>
                    </tr>
                    <tr>
                      <th>Dosen Pembimbing 1</th>
                      <th><span>:</span></th>
                      <td>{{ detail.teacher_mentor_1_name }}</td>
                    </tr>
                    <tr>
                      <th>Dosen Pembimbing 2</th>
                      <th><span>:</span></th>
                      <td>{{ detail.teacher_mentor_2_name }}</td>
                    </tr>
                    <tr>
                      <th>Keterangan</th>
                      <th><span>:</span></th>
                      <td>{{ detail.notes }}</td>
                    </tr>
                    <tr>
                      <th>Waktu Penyerahan Sampel</th>
                      <th><span>:</span></th>
                      <td>{{ detail.submission_time_display }}</td>
                    </tr>
                    <tr>
                      <th>Waktu Pengambilan Hasil SEM</th>
                      <th><span>:</span></th>
                      <td>{{ detail.submission_time_display }}</td>
                    </tr>
                  </table>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="primary"
                      @click="$router.push('/sem-registration')"
                    >
                      Tutup
                    </b-button>
                    <!-- <b-button
                      type="button"
                      variant="outline-primary"
                      class="ml-2 btn-hover-secondary"
                      @click="$router.push('/sem-registration/edit/' + $route.params.id)"
                      v-if="btnAccess"
                    >
                      Edit
                    </b-button> -->
                  </div>
                </div>
                <!--end: Wizard Actions -->
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      detail: {},
      // access
      btnAccess: true,
    };
  },
  methods: {
    async get() {
      this.detail = await module.get(
        "api/sem-registration-forms/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/sem-registration");
      }
    },

    setFileName(data){
        console.log('data', data)
        let filename = data.parental_consent.split("_____")
        return filename[filename.length]
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8016") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pendaftaran Karakteristik SEM", route: "/sem-registration" },
      { title: "Detail" },
    ]);

    this.get();
    this.checkAccess();
  },
};
</script>
